import check from './check';

function sendForm(formSelector, exampleSelector, resultValueSelector, btnSubmit, loaderSelector, classActiveLoader, buttonClose, overlaySelector, modalInfoSelector, classActiveOverlay) {
    const form = document.querySelector(formSelector),
          checkResult = document.querySelector(resultValueSelector),
          buttonSubmit = document.querySelector(btnSubmit),
          checkExample = document.querySelector(exampleSelector),
          buttonCloseElement = document.querySelector(buttonClose);

    let checkObj = check(),
          messageObj = {
            success: [`Ваша анкета отправлена, ожидайте обратной связи от администратора, чтобы ускорить процесс обработки анкеты, напишите, пожалуйста, администратору в Telegram - <a href="https://t.me/RenatJobs" class="modal__info-link">https://t.me/RenatJobs</a>`, '#aaec1e'],
            error: [`Не удалось отправить Вашу анкету, свяжитесь, пожалуйста, с администратором в Telegram - <a href="https://t.me/RenatJobs" class="modal__info-link">https://t.me/RenatJobs</a>`, '#DB0000']
          };

    checkExample.textContent = checkObj.example;

   
    form.addEventListener('submit', (e) => {
        e.preventDefault();

        if (+checkResult.value == checkObj.result) {
            buttonSubmit.innerHTML = `<span class="${loaderSelector} ${classActiveLoader}"></span>`;

            // reCaptcha Google ----start-----
            let formData;

            grecaptcha.enterprise.ready(async () => {
                await grecaptcha.enterprise.execute('6LcsdqInAAAAAI5DYzusvEJqCZ78Tvc4Z9URqn5X', {action: 'homepage'}).then(function(token) {
                   
                    document.getElementById('token').value = token;
                    
                    formData = new FormData(form);
                    fetch('../mailer/send.php', {
                        method: 'POST',
                        body: formData,
                    })
                    .then(response => response.json())
                    .then(result => {
                        if (result["on_score"] >= 0.5) {
                            // Отправка данных на почту
                            send();
                        } else {
                            openModal(overlaySelector, modalInfoSelector, messageObj.error);
                            checkObj = check();
                            checkExample.textContent = checkObj.example;
                        }
                    });

                    // reCaptcha Google ----end-----
                    
                });
            });


            async function send() {
                let response = await fetch('../mailer/smart.php', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    let result = await response.json();
                    if(result.message != "Ошибка!") {
                        form.reset();
                        openModal(overlaySelector, modalInfoSelector, messageObj.success);
                    }
                    else {
                        openModal(overlaySelector, modalInfoSelector, messageObj.error);
                    }
                    
                    buttonSubmit.innerHTML = `Отправить анкету`;
                    checkObj = check();
                    checkExample.textContent = checkObj.example;
                } else {
                    buttonSubmit.innerHTML = `Отправить анкету`;
                    openModal(overlaySelector, modalInfoSelector, messageObj.error);
                    checkObj = check();
                    checkExample.textContent = checkObj.example;
                }
            }

            buttonCloseElement.addEventListener('click', () => {
                closeModal(overlaySelector);
            })

            function openModal(modalSelector, modalInfo, messageModal) {
                const modalWindow = document.querySelector(modalSelector),
                      modalInfoElement = document.querySelector(modalInfo);
                modalInfoElement.innerHTML = messageModal[0];
                document.querySelector('.modal').style.borderColor = messageModal[1];
                modalWindow.classList.add(classActiveOverlay);
                document.body.style.overflow = 'hidden';
            }

            function closeModal(modalSelector) {
                const modalWindow = document.querySelector(modalSelector);
                modalWindow.classList.remove(classActiveOverlay);
                document.body.style.overflow = '';
            }

            document.addEventListener('keydown', (e) => {
                const modalWindow = document.querySelector(overlaySelector);
                if (e.code === "Escape" && modalWindow.classList.contains(classActiveOverlay)) {
                    closeModal(overlaySelector);
                }
            });

        } else {
            checkResult.value = "Значение указано неверно!";
        }
    });
}

export default sendForm;