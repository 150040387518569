function slider({container, slide, nextArrow, prevArrow, wrapper, field}) {
    const slides = document.querySelectorAll(slide),
          slider = document.querySelector(container),
          btnSlidePrev = document.querySelector(prevArrow),
          btnSlideNext = document.querySelector(nextArrow),
          slidesWrapper = document.querySelector(wrapper),
          slidesField = document.querySelector(field),
          width = window.getComputedStyle(slides[0]).width,
          widthWrapper = window.getComputedStyle(slidesWrapper).width;
    let kfSlide = Math.floor(parseInt(widthWrapper) / parseInt(width)),
        offset = 0;
        
    if (slides.length  < 5 && slides.length > 1) {
        if ((slides.length - 1) < kfSlide) {
            kfSlide = slides.length - 1;
        }
    } else if (slides.length == 1) {
        kfSlide = slides.length;
    }

    slidesField.style.width = parseInt(width) * slides.length + 'px';
    slidesField.style.display = 'flex';
    slidesField.style.transition = '0.5s all';
    slidesWrapper.style.overflow = 'hidden';


    btnSlideNext.addEventListener('click', () => {
        if (offset == parseInt(width) * (slides.length - kfSlide)) {
            offset = 0;
        } else {
            offset += parseInt(width);
        }

        slidesField.style.transform = `translateX(-${offset}px)`;


    });

    btnSlidePrev.addEventListener('click', () => {
        if (offset == 0) {
            offset = parseInt(width) * (slides.length - kfSlide);
        } else {
            offset -= parseInt(width);  
        }

        slidesField.style.transform = `translateX(-${offset}px)`;

    }); 
}

export default slider;