function menu(menuContainer, itemMenu, hamburgerElement, menuClassActive, hamburgerClassActive) {
    const menu = document.querySelector(menuContainer),
    menuItem = document.querySelectorAll(itemMenu),
    hamburger = document.querySelector(hamburgerElement);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerClassActive);
        menu.classList.toggle(menuClassActive);
    });

    menuItem.forEach(item => {
        item.addEventListener('click', (e) => {
            if (!e.target.classList.contains('header__item-link_drop')){
                hamburger.classList.toggle(hamburgerClassActive);
                menu.classList.toggle(menuClassActive);
            }
        })
    })
}

export default menu;

