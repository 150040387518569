function lang(triggerSelector, langBlock, activeClass, langElement) {
    const button = document.querySelector(triggerSelector),
          langBlck = document.querySelector(langBlock),
          langEl = document.querySelectorAll(langElement);

    button.addEventListener('click', () => {
        if (langBlck.classList.contains(activeClass)) {
            langBlck.classList.remove(activeClass);
            langEl.forEach(item => {
                item.style.display = 'none';
            });
        } else {
            langBlck.classList.add(activeClass);
            langEl.forEach(item => {
                item.style.display = 'block';
            });
        }
    });
}

export default lang;