function check() {
    // const exampleContainer = document.querySelector(selectorExampleContainer),
    //       resultValue = document.querySelector(selectorResultValue);
    
    const numberOne = Math.floor((Math.random() * (0.25 - 0.05) + 0.05) * 100);
    const numberTwo = Math.floor((Math.random() * (0.25 - 0.05) + 0.05) * 100);

    // exampleContainer.textContent = `Укажите ответ на пример: ${numberOne} + ${numberTwo}`;
    
    
    return {result: numberOne + numberTwo, example: `Укажите ответ на пример: ${numberOne} + ${numberTwo}`};
}



export default check;