function hide(buttonSelector, formContainerSelector, formSelector, activeClass) {
    const btn = document.querySelector(buttonSelector),
          formContainer = document.querySelector(formContainerSelector),
          form = document.querySelector(formSelector);

    btn.addEventListener('click', () => {
        if (formContainer.classList.contains(activeClass)) {
            formContainer.classList.remove(activeClass);
            form.style.display = 'none';
            btn.textContent = 'Заполнить анкету';
        } else {
            formContainer.classList.add(activeClass);
            form.style.display = 'flex';
            btn.textContent = 'Скрыть';
        }        
    });
}

export default hide;